export const REMOTE_BASE_URL = "https://cdn.bosslogics.live";
export const REACT_APP_PUBLIC_API_KEY = process.env.REACT_APP_DEFAULT_API_KEY;
export const IDENTITY_AUTH_PROXY = process.env.REACT_APP_IDENTITY_AUTH_PROXY;
export const FONT_SERVER = process.env.REACT_APP_FONT_SERVER;
export const PAGE_TITLE_SUFFIX = " | BOSS Logics Live Catalog";

export const CATALOG_SEARCH_PATHS = {
	id: true,
	label: true,
	title: true,
	initial_uri: true,
	uri: true,
	slug: true,
	options: true,
	account: {
		id: true,
		name: true,
		code: true,
		type: { code: true },
	}
};
export const CATALOG_PATHS = {
	id: true,
	label: true,
	title: true,
	initial_uri: true,
	uri: true,
	slug: true,
	options: true,
	pages: {
		id: true,
		label: true,
		uri: true,
		alias_slug: true,
		link: true,
		slug: true,
		rank: true,
		parent: true,
		module_set: true,
		type: {
			code: true,
		},
		public: true,
		icon: true,
		title: true,
		options: true,
		heading: true,
		subheading: true,
		copy: true,
		hidden: true,
		status: true
	},
	account: {
		id: true,
		name: true,
		code: true,
		type: { code: true },
	},
	thumb_fieldset: {
		id: true,
		handle: true,
		fields: {
			id: true,
			options: true,
			common: {
				id: true,
				label: true,
				options: true
			}
		}
	},
	detail_fieldset: {
		id: true,
		handle: true,
		fields: {
			id: true,
			options: true,
			common: {
				id: true,
				label: true,
				options: true
			}
		}
	},
	footer: true
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const winConfig = window._bossLiveConfig || {};

const getConfigValue = key => {
	return winConfig[key]
		|| urlParams.get(key)
		|| localStorage.getItem('rebase.' + key)
		|| "";
};

const getAndSetConfigValue = key => {
	const value = getConfigValue(key);
	value && localStorage.setItem('rebase.' + key, value);
	return value;
};

export const BASE_PATH = getAndSetConfigValue('basePath');
export const DOM_ROOT_ID = getAndSetConfigValue('domRootId');
export const X_API_KEY = getAndSetConfigValue('xApiKey');
export const STATIC_CATALOG = winConfig.catalog;
export const SINGLE_PAGE = winConfig['singlePage'];
// TODO !!!! fix later ... add API_Key_Options class or something like that
export const EMBED_NAVIGATION = [
	"jmFA_1pzPavR3iceN0wIMvXzEYq127blAtctbpCqdSQ",  // deboulle
	"HZhtmxZln7X7grxZDcf5cxKIj1T9E-2RTpOxzTyiGEk"  // caratco
].includes(X_API_KEY?.split(".")?.at(-1));

export const DEFAULT_BG_COLOR = "#E2E8F0";
export const DEFAULT_FG_COLOR = "#FFFFFF";
export const DEFAULT_THEME_TEMPLATE = {
	themes: {
		header: {
			foreground:{
				color: DEFAULT_FG_COLOR
			},
			background: {
				color: DEFAULT_BG_COLOR
			}
		}
	}
}