import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { BASE_PATH, STATIC_CATALOG } from "./constants";

import { hasValue } from "./util/Functions";
import CatalogContext from "./contexts/CatalogContext";
import LoadMask from "./components/LoadMask";

import { useAuth } from "./auth/useAuth";
import ApiKeyContext from "./contexts/ApiKeyContext";
import ApiDrivenModel from "./models/ApiDrivenModel";
import Organization from "./models/Organization";
import OrganizationMember from "./models/OrganizationMember";
import { DialogContextProvider } from "./hooks/useDialogContext";
import { SidebarContextProvider } from "./hooks/useSidebarContext";
import Associate from "./models/Associate";
import useGlobalStyles from "./hooks/GlobalStyles";
import { Global } from "@emotion/react";

const PublicLayout = lazy(() => import("./layouts/public/PublicLayout"));
const ProtectedLayout = lazy(() => import("./layouts/protected/ProtectedLayout"));
const PrivateLayout = lazy(() => import("./layouts/private/PrivateLayout"));


function Appin() {
	const [catalog] = React.useContext(CatalogContext);
	const [template, setTemplate] = React.useState(STATIC_CATALOG ? 'private' : false);

	const urlSearchParams = new URLSearchParams(window.location.search);
	const redirect = urlSearchParams.get("redirect")
		&& decodeURIComponent(urlSearchParams.get("redirect"));

	const pathParts = window.location.pathname.trim('/').split('/').filter(hasValue);
	const { currentUser, setOrganizationMember } = useAuth();
	const protectedPageRoots = ['user', 'catalog', 'email-verification'];
	const publicPageRoots = ['login', 'register', 'reset-password', 'pin-login', 'public'];

	React.useEffect(() => {
		const currentUserInfo = JSON.parse(localStorage.getItem('organization_member:data'));
		const branding = currentUserInfo?.organization?.branding;
		const brandForegroundColor = branding?.themes?.header?.foreground?.color || undefined;
		const brandBackgroundColor = branding?.themes?.header?.background?.color || undefined;
		const catalogForegroundColor = catalog?.options?.appearance?.header?.color || undefined;
		const catalogBackgroundColor = catalog?.options?.appearance?.header?.background_color || undefined;
		let foregroundColor = catalogForegroundColor || brandForegroundColor || "#000000";
		let backgroundColor = catalogBackgroundColor || brandBackgroundColor || "#ffffff";

		if (foregroundColor.toLowerCase() === backgroundColor.toLowerCase()) {
			foregroundColor = brandForegroundColor;
			backgroundColor = brandBackgroundColor;
		}

		// need these in body because modals and sidebars are created outside #boss container
		const body = document.body;
		body.style.setProperty("--foreground-color", foregroundColor);
		body.style.setProperty("--background-color", backgroundColor);
		body.style.setProperty("--brand-foreground-color", brandForegroundColor || "#000000");
		body.style.setProperty("--brand-background-color", brandBackgroundColor || "#FFFFFF");
	}, [catalog]);

	const API_KEY_CONTEXT = React.useContext(ApiKeyContext);
	ApiDrivenModel.CURRENT_CONTEXT = API_KEY_CONTEXT;

	const fetch_organization_member = async () => {
		const member = await OrganizationMember.FetchBy({
			filter: [{
				property: '{profile}',
				value: "@profile_id"
			}],
			paths: {
				id: true,
				profile: {
					id: true,
					username: true,
					email: true,
					given_name: true,
					family_name: true,
					pronouns: true,
					picture: true,
					mobile_phone: true,
					last_activity: true,
					public_token: true,
				},
				organization: {
					id: true,
					active: true,
					address: true,
					administrative_area: true,
					country: true,
					formatted_address: true,
					locality: true,
					name: true,
					postal_code: true,
					sub_administrative_area: true,
					logo: true,
					branding: true,
					parent: {
						id: true,
						logo: true,
						branding: true
					}
				},
				role: {
					id: true,
					is_administrator: true,
					label: true,
					access: true
				},
				verified: true
			},
		}, null, true)

		if (member) {
			return member;
		}

		const associate = await Associate.FetchBy({ filter: [ { property: "email", value: currentUser.email } ] });
		
		if (!associate?.account?.organization) {
			return;
		}
		const organization = new Organization();
		organization.setProperties({ id: associate.account.organization });
		const { success } = await organization.linkAssociate(associate.id);
		success && window.location.reload();
	}

	React.useEffect(() => {
		//if localStorage isn't a proper object, then delete it.
		try {
			let localOrgMember = localStorage.getItem('organization_member:data');
			if (!JSON.parse(localOrgMember) || typeof JSON.parse(localOrgMember) !== 'object') {
				throw new TypeError('error with localStorage')
			}
		} catch (e) {
			localStorage.removeItem('organization_member:data');
		}

		//this usually won't run because localStorage is set upon logging in. 
		//this is just in case localStorage is edited by user, or localStorage is holding invalid JSON object
		if (currentUser) {
			const stored_member = localStorage.getItem("organization_member:data");
			if (stored_member) {
				setOrganizationMember(JSON.parse(stored_member));
			}

			fetch_organization_member().then(response => {
				if (response) {
					response.cleanForSave();
					delete response._system;
					localStorage.setItem('organization_member:data', JSON.stringify(response));
					setOrganizationMember(response);
				}
			});
		}

		const getTemplate = () => {
			if (STATIC_CATALOG) {
				return setTemplate("private");
			}
			if (!pathParts.length || publicPageRoots.includes(pathParts[0])) {
				// if (!currentUser || !pathParts.length || publicPageRoots.includes(pathParts[0])) {
				return setTemplate("public");
			}
			return setTemplate(
				!pathParts.length || protectedPageRoots.includes(pathParts[0])
					? "protected"
					: "private"
			);
		}
		getTemplate();


		if (currentUser && window.location.pathname === "/login") {
			window.location = redirect || '/catalog/select';
		}

	}, [currentUser]);

	const getModule = template => {
		switch (template) {
			case 'public':
				return (<PublicLayout />);
			case 'protected':
				return (<ProtectedLayout />);
			case 'private':
				return (
					<SidebarContextProvider>
						<PrivateLayout />
					</SidebarContextProvider>
				);
			default: return null;
		}
	};

	const wrapperClasses = [
		"bossLiveCatalogWrapper",
		catalog?.options?.type && "type-" + catalog.options.type,
		(((catalog !== null) || (template !== "private")) && template)
	];
	
	const GlobalStyles = useGlobalStyles(catalog?.options?.appearance);
	
	return (
		<>
		<Global styles={GlobalStyles} />
		<div className="_boss" id="_boss">
			<DialogContextProvider>
				<div className={wrapperClasses.filter(a => a).join(" ")}>
					<Router basename={BASE_PATH}>
						<Suspense fallback={<LoadMask show={true} />}>
							{getModule(template)}
						</Suspense>
					</Router>
				</div>
			</DialogContextProvider>
		</div>
		</>
	);
}

export default Appin;
