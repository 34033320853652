import ApiDrivenModel from "./ApiDrivenModel";

export default class Associate extends ApiDrivenModel {
	static ENDPOINT = "/Associate";
	static PATHS = {
		id: true,
		handle: true,
		first_name: true,
		last_name: true,
		profile: {
			handle: true,
			given_name: true,
			family_name: true,
			picture: true,
			last_login: true,
		},
		email: true,
		account: {
			organization: {
				id: true,
				name: true,
				formatted_address: true,
				logo: true,
			},
		},
		type: [ "code" ],
	};


}
