import React from "react";
import { createRoot } from "react-dom/client";
import { Button } from "react-bootstrap";


class DialogContextStructure {
	/**
	 * @param {DialogDefType} options
	 * @returns Promise<String>
	 */
	open (options) {}
}

const DialogContext = React.createContext(new DialogContextStructure());

/**
 * @returns DialogContextStructure
 */
export const useDialogContext = () => {
	const context = React.useContext(DialogContext);
	if (!context) {
		throw new Error(
			"useDialogContext must be used within a DialogContextProvider"
		);
	}
	return context;
};

export const DialogContextProvider = ({ children }) => {
	const dialogsContainerRef = React.useRef(undefined);
	
	/**
	 * @param {DialogDefType} options
	 * @returns Promise
	 */
	const openDialog = async options => {
		const dialogInstance = await makeDialog(options);
		dialogsContainerRef.current.append(dialogInstance);
		dialogInstance.showModal();
		
		options.actionBus?.on("close", () => {
			dialogInstance.close();
			dialogInstance.remove();
		});
		options.actionBus?.on("processing", () => {
			dialogInstance.classList.add("processing");
		});
		let preventAutoClose = false;
		options.actionBus?.on("prevent-auto-close", () => {
			preventAutoClose = true;
		});
		
		return new Promise(resolve => {
			dialogInstance.addEventListener("click", e => {
				if (e.target && e.target instanceof HTMLButtonElement) {
					const actionKey = e.target.dataset.actionKey;
					const action = options.actions?.find(it => it.key === actionKey);
					action && resolve(action.key);
					if (!preventAutoClose) {
						dialogInstance.close();
						dialogInstance.remove();
					}
				}
			});
		});
	}
	
	/**
	 * @param {DialogDefType} options
	 * @returns Promise<HTMLDialogElement>
	 */
	const makeDialog = options => {
		const comp = (
			<dialog data-dialog-key={options.key}>
				<h3 className="header">{options.heading}</h3>
				<section className="content">
					{options.content}
				</section>
				<footer>
					{
						!options.actions?.find(it => it.key === "cancel" || it.key === "close") && (
							<Button data-action-key="close">Close</Button>
						)
					}
					{options.actions && (
						<section>
							{options.actions?.map(action => (
								<Button key={action.key} data-action-key={action.key}>{action.label}</Button>
							))}
						</section>
					)}
				</footer>
			</dialog>
		);
		
		const wrapper = document.createElement("div");
		const root = createRoot(wrapper);
		root.render(comp);
		
		// timeout is to allow for rendering, appending, etc before attempting to launch
		return new Promise(resolve => {
			setTimeout(() => resolve(wrapper.firstElementChild), 150);
		});
	}
	
	const value = {
		open: openDialog,
	}
	
	return (
		<DialogContext.Provider value={value} displayName="Dialog Context">
			<div data-component="Dialogs" ref={dialogsContainerRef}></div>
			
			{children}
		</DialogContext.Provider>
	);
};
