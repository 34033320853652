import { css } from "@emotion/react";
import { isUrl } from "../util/Functions";
import { FONT_SERVER, EMBED_NAVIGATION } from "../constants";

function normalize_colors(background, foreground) {
	let foregroundColor = foreground || "#000000";
	let backgroundColor = background || "#ffffff";
	
	if (foregroundColor.toLowerCase().substring(0, 3) === backgroundColor.toLowerCase().substring(0, 3)) {
		if (foreground && !background) {
			foregroundColor = foreground;
			backgroundColor = foregroundColor.startsWith("#000") ? "#ffffff" : "#000000";
		} else if (background && !foreground) {
			backgroundColor = background;
			foregroundColor = backgroundColor.startsWith("#000") ? "#ffffff" : "#000000";
		}
	}
	
	return [backgroundColor, foregroundColor];
}

function useGlobalStyles(styles) {
	if (!styles) return null;
	const header_font_url_obj = parse_font_family_url(
		styles?.header?.font_family
	);
	const body_font_url_obj = parse_font_family_url(styles?.body?.font_family);
	
	const item_border_colors =
		styles?.grid?.items.color &&
		`
			.catalog-page .products-gallery .grid-layout .cell { border-color: ${styles.grid.items.color} !important; }
			.catalog-page .products-gallery .grid-layout .cell .card { border-color: ${styles.grid.items.color} !important; }
			.catalog-page .grid-border-color { border-color: ${styles.grid.items.color}80 !important; }
			.catalog-page ._b-filters-component #filter-by { border-color: ${styles.grid.items.color}80; }
			.catalog-page .page-item .page-link { border-color: ${styles.grid.items.color} !important; }
	`;
	const asset_border_colors =
		styles?.grid?.assets?.color &&
		`
			.grid-layout.asset-grid .cell { border-color: ${styles.grid.assets.color} !important; }
			.grid-layout.asset-grid .cell .card { border-color: ${styles.grid.assets.color} !important; }
			.asset-gallery .grid-border-color { border-color: ${styles.grid.assets.color}80 !important; }
			.page-item .page-link { border-color: ${styles.grid.assets.color} !important; }
	`;
	
	let imports = [];
	let styles_parsed = styles.custom_css;
	if (styles.custom_css) {
		imports = [...styles.custom_css.matchAll(/(@import url\(.*)\);/gi)].map(s => s[0]);
		styles_parsed = styles.custom_css.replace(/(@import url\(.*)\);/gi, '');
	}
	
	const [primary_button_bg, primary_button_fg] = normalize_colors(styles?.primary_button?.background_color, styles?.primary_button?.color);
	const [secondary_button_bg, secondary_button_fg] = normalize_colors(styles?.secondary_button?.background_color, styles?.secondary_button?.color);
	const [body_bg, body_fg] = normalize_colors(styles?.body?.background_color, styles?.body?.color);
	const [footer_bg, footer_fg] = normalize_colors(styles?.footer?.background_color, styles?.footer?.color);
	
	return css`
        ${header_font_url_obj ? `@import url(${header_font_url_obj});` : ""}
        ${body_font_url_obj ? `@import url(${body_font_url_obj});` : ""}
        ${imports.join("\n")}
        body {
            font-size: 14px;
        }

        #_boss._boss, ._boss-offcanvas {
            ${FONT_SERVER ? "--font-server: '" + FONT_SERVER + "';" : ""}
            --highlight-color: ${styles?.header?.highlight_color || "#09f"};
	        
	        --headers-position: ${EMBED_NAVIGATION ? "relative" : ""};
	        --embeded-header-z-index: ${EMBED_NAVIGATION ? 1 : ""};

            --header-color: ${"var(--foreground-color)"};
            --header-bg: ${"var(--background-color)"};
            --header-font-family: ${parse_font_family(styles?.header?.font_family || "Comfortaa, Quicksand, sans-serif")};
            --header-font-size: ${styles?.header?.font_size || "--bs-body-font-size"};
            --header-font-weight: ${styles?.header?.font_weight || "normal"};

            --footer-bg: ${footer_bg};
            --footer-color: ${footer_fg};
            --footer-font-size: ${styles?.footer?.font_size || "--bs-body-font-size"};
            --footer-font-family: ${parse_font_family(styles?.footer?.font_family)};
            --footer-font-weight: ${styles?.footer?.font_weight || "normal"};

            --highlight-fg: ${"var(--background-color)"};
            --highlight-bg: ${"var(--foreground-color)"};

            --bs-body-color: ${body_fg};
            --bs-body-bg: ${body_bg};
            --bs-body-font-family: ${parse_font_family(styles?.body?.font_family)};
            --bs-body-font-size: ${styles?.body?.font_size || "var(--bs-body-font-size)"};

            --bs-dropdown-item-padding-x: 1rem;
            --bs-dropdown-item-padding-y: 0.25rem;
            --border-color: ${styles?.grid?.items?.color};
	        
            --button-primary-bg: ${primary_button_bg};
            --button-primary-color: ${primary_button_fg};
            --button-primary-font-size: ${styles?.primary_button?.font_size || "var(--bs-body-font-size)"};
            --button-primary-font-family: ${parse_font_family(styles?.primary_button?.font_family)};

            --button-secondary-bg: ${secondary_button_bg};
            --button-secondary-color: ${secondary_button_fg};
            --button-secondary-font-size: ${styles?.secondary_button?.font_size || "var(--bs-body-font-size)"};
            --button-secondary-font-family: ${parse_font_family(styles?.secondary_button?.font_family)};

            ${styles_parsed ? styles_parsed.replace(/\._boss|#_boss/g, '') : ''}
            
            label {
                position: unset; // TODO: this is cuz of caracto
            }
            
            header {
                .user-nav.nav-item a {
                    color: var(--header-color);
                }
            }

            nav {
                .nav-link,
                .nav-link:hover,
                .nav-link:focus {
                    color: var(--header-bg);
                }

                .nav-link:hover {
                    opacity: 0.7;
                }
            }

            button.btn-link {
                color: var(--button-primary-bg);

                &:hover {
                    color: var(--button-primary-bg);
                    opacity: 0.7;
                }
            }

            button.btn-primary:not(.dropdown-button) {
                background-color: var(--button-primary-bg);
                color: var(--button-primary-color);
                border-color: var(--button-primary-color);
                border: 0;

                &:hover {
                    opacity: 0.8;
                }
            }

            button.btn-outline-secondary {
                background-color: var(--button-secondary-bg);
                color: var(--button-secondary-color);
                border-color: var(--button-secondary-color);

                &:hover {
                    opacity: 0.8;
                }
            }

            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            font-size: var(--bs-body-font-size);

            .page-link,
            .page-link:hover {
                color: var(--bs-body-color);
            }

            .page-item.active > .page-link {
                color: var(--header-color);
                background-color: var(--header-bg);
            }

            .delete.btn-link {
                color: var(--bs-body-color);
            }

            ${item_border_colors}
            ${asset_border_colors}
        }
	`;
}

function parse_font_family_url(font_family) {
	return isUrl(font_family) ? new URL(font_family) : false;
}

function parse_font_family(font_family) {
	const urlObj = parse_font_family_url(font_family);
	
	if (!urlObj) {
		return font_family;
	}
	
	const font_family_value = new URLSearchParams(urlObj.search).get("family")
		|| urlObj.pathname.substring(urlObj.pathname.lastIndexOf('/') + 1).split('.')?.at(0);
	
	// TODO .......... fetched google font returning with extra style (ital) and font weights .. that need to get stripped
	return font_family_value?.split(":")?.at(0);
}

export default useGlobalStyles;
